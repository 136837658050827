//
// approveType： 1、出差单，2、超标单，3、报销单，4、改签单，5、退订单，
// type：1、酒店退订，2、机票退订，3、火车票退订，4、机票改签，5、火车票改签，
// fromType：1、我的申请详情，2、我的参与详情，3、已审批详情，4、未审批详情，5、我的出差详情，6、我的预定详情，7、企业待支付详情，8、企业已支付详情，

import moment from 'moment'

//  出差单详情
import journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail";
// 出差申请单详情
import applyDetail from "@/lib/data-service/haolv-default/consumer_apply_detail";
// 超标申请单详情
import overproofDetail from "@/lib/data-service/haolv-default/consumer_overproof_detail";
//改签申请单详情
import getApplyChange from "@/lib/data-service/haolv-default/consumer_journey_getApplyChange";
//退票申请单详情
import getApplyRefund from "@/lib/data-service/haolv-default/consumer_journey_getApplyRefund";

export default {
  data() {
    return {
      loading: false,
      fromType: "",
      approveType: '',
      detail: {
        approveNode: [],
        awaitApproveNum: 0,
      },
    };
  },
  methods: {
    init() {
      this.loading = true;
      const _this = this;
      const query = _this.$route.query;
      _this.fromType = Number(query.fromType);
      _this.approveType = Number(query.approveType);
      
      const fromType = _this.fromType;

      const applyOrder = [1, 2, 3, 4];
      const evectionOrder = [5, 6, 7, 8];

      // 格式化审批节点
      const format_approveNode = (detail) => {
        // 发起人
        detail.approveNode = [{approverUserName: detail.initialName, approveStatus: -1, deptName: '', createDate: _this.$moment(detail.gmtCreate).format('YYYY-MM-DD HH:mm:ss'),}]
        // 其他审批节点
        detail.recordList.forEach((item) => {
          if (item.approveStatus === 1) detail.awaitApproveNum += 1;
          detail.approveNode.push({
            approverUserName: item.approverUserName,
            approveStatus: item.approveStatus,
            deptName: item.deptName,
            createDate: _this.$moment(item.gmtModified).format('YYYY-MM-DD HH:mm:ss'),
          })
        })
      };


      if (applyOrder.includes(fromType)) {
        const approveType = _this.approveType;
        const id = Number(query.id);

        const myRequest = (myMethod, params) => {
          myMethod(params).then((res) => {
            let detail = {};
            // format_approveNode(detail);
            // 出差
            if (approveType === 1) {
              detail = {...res.datas, ...res.datas.evection, awaitApproveNum: 0};
              format_approveNode(detail);
              detail.staffDetailVos = detail.staffDetailVos.map((item) => item.staffName).join('，');
              // 超标
            } else if (approveType === 2) {
              detail = {...res.datas, ...res.datas.evectionDetailResponse, awaitApproveNum: 0};
              detail.staffDetailVos = detail.tripPersons.join('，');
              // format_approveNode(detail);
              // 报销
            } else if (approveType === 3) {
              // 改签
            } else if (approveType === 4) {
              // 退订
            } else if (approveType === 5) {
              
            }

            _this.detail = detail;
            _this.loading = false;
          }).catch((err) => {
            _this.loading = false;
          })
        };


        if (approveType === 1) {
          myRequest(applyDetail, {id});
        } else if (approveType === 2) {
          myRequest(overproofDetail, {applyId: id});
        } else if (approveType === 3) {
          
        } else if (approveType === 4) {
          
        } else if (approveType === 5) {
          
        }

      } else if (evectionOrder.includes(fromType)) {
        // , pageType: 2, personType: 2
        journey_getEvectionDetail({ evectionNo: query.evectionNo })
          .then((res) => {
            let detail = res.datas;
            format_approveNode(detail);

            _this.detail = detail;
            _this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
        console.log("evectionOrder");
      }
    },
    format_status(val) {
      switch (Number(val)) {
        case -1:
          return { text: "发起时间：", class: "success" };
          break;
        case 0:
          return { text: "未开始", class: "notstart" };
          break;
        case 1:
          return { text: "待审核...", class: "pending" };
          break;
        case 2:
          return { text: "审核通过", class: "success" };
          break;
        case 3:
          return { text: "已拒绝", class: "fail" };
          break;
        case 4:
          return { text: "已失效", class: "failure" };
          break;
      }
    },

    downonload_accessoryUrl(url) {
      window.open(url);
    },

    

    to_apply_detail () {
      this.$router.push({
        name: 'admin-apply-detail',
      })
    },


    to_flight_detail (orderNo) {
      this.$router.push({
        name: 'admin-my-order-flight-detail',
        query: {
          orderNo
        }
      })
    },


    to_train_detail (orderNo) {
      this.$router.push({
        name: 'admin-my-order-train-detail',
        query: {
          orderNo
        }
      })
    },


    to_hotel_detail (orderNo) {
      this.$router.push({
        name: 'admin-hotel-order-detail',
        query: {
          orderNo
        }
      })
    },

    back() {
      this.$router.back();
    },
  },
  beforeRouteEnter(to, form, next) {
    console.log(to);
    switch (Number(to.query.fromType)) {
      case 1:
        to.meta.lastMenu = "adminMyApply";
        break;
      case 2:
        to.meta.lastMenu = "adminMyApplyParticipation";
        break;
      case 3:
        to.meta.lastMenu = "adminHaveApprove";
        break;
      case 4:
        to.meta.lastMenu = "adminNoApprove";
        break;
      case 5:
        to.meta.lastMenu = "adminMyEvection";
        break;
      case 6:
        to.meta.lastMenu = "adminMyReserve";
        break;
      case 7:
        to.meta.lastMenu = "enterprisesNoPay";
        break;
      case 8:
        to.meta.lastMenu = "enterprisesPay";
        break;
    }
    next();
  },
  activated() {
    this.init();
  },
  filters: {
    format_week(val) {
      switch (moment(val).day()) {
        case 0:
          return "周日";
          break;
        case 1:
          return "周一";
          break;
        case 2:
          return "周二";
          break;
        case 3:
          return "周三";
          break;
        case 4:
          return "周四";
          break;
        case 5:
          return "周五";
          break;
        case 6:
          return "周六";
          break;
      }
    },
    format_duration(val) {
      return val.replace("h", "小时").replace("m", "分钟");
    },
  },
};
