/*
*退 审批详情
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/journey/getApplyRefund',
        data: data
    }
    return __request(pParameter)
}
export default request